import React, { useEffect } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  RouteObject,
} from 'react-router-dom'
import notAuthenticated from '@/plugins/auth/RouteRedirectors/notAuthenticated'
import RootRedirector from '@/plugins/auth/RouteRedirectors/RootRedirector'
import { authenticatedDynImport } from '@/plugins/auth/RouteRedirectors/authenticated'
import useConst from '@/hooks/useConst'
import Analytics from '../plugins/Analytics'
import LandingPage from './LandingPage'
import NotFound from './NotFound'
import Header from './Header/Header'

const AppFrame: React.FC = () => {
  const lazyRoutes = useConst({
    HelpCenter: () => import('./HelpCenter/HelpCenter'),
    LeaderBoardPage: () => import('./SpaceLasers/LeaderBoardPage'),
    ItemsPage: () => import('./Items/ItemsPage'),
    Stepper: () => authenticatedDynImport(import('./Stepper/Stepper')),
    Username: () => authenticatedDynImport(import('./Identity/Username/Username')),
    CreateItem: () => authenticatedDynImport(import('./Studio/CreateItem')),
    HomePage: () => authenticatedDynImport(import('./HomePage')),
    Profile: () => authenticatedDynImport(import('./Profile/Profile')),
    Send: () => authenticatedDynImport(import('./Transfer/Send')),
    MyItems: () => authenticatedDynImport(import('./Items/MyItems')),
    Activity: () => authenticatedDynImport(import('./Activity/Activity')),
    Studio: () => authenticatedDynImport(import('./Studio/Studio')),
    TemplatePage: () => authenticatedDynImport(import('./Studio/Templates/TemplatePage')),
    DiscoverPage: () => authenticatedDynImport(import('./Discover/DiscoverPage')),
    SpacesPage: () => import('./Discover/SpacesPage'),
    Airdrop: () => authenticatedDynImport(import('./Airdrop/Airdrop')),
    UserSettings: () => authenticatedDynImport(import('./Settings/UserSettings')),
  })

  useEffect(() => {
    // preload other routes after the page has loaded
    const preloadLazyRoutes = async () => {
      await Promise.allSettled(
        (Object.keys(lazyRoutes) as (keyof typeof lazyRoutes)[]).map(key =>
          lazyRoutes[key]().catch(console.error)
        )
      )
    }
    window.addEventListener('load', preloadLazyRoutes)
    return () => window.removeEventListener('load', preloadLazyRoutes)
  }, [lazyRoutes])

  const router = useConst.fn(() => {
    const routesObj: RouteObject[] = createRoutesFromElements(
      <Route path="/" Component={Analytics}>
        <Route path="/" element={<RootRedirector />} />
        <Route path="/loggedIn/*" element={<RootRedirector />} />

        <Route path="/connect" element={notAuthenticated(<LandingPage />, 'child')} />
        <Route path="/intro" lazy={lazyRoutes.Stepper} />
        <Route path="/freeusername" lazy={lazyRoutes.Username} />
        <Route path="/premiumusername" lazy={lazyRoutes.Username} />
        <Route path="/leaderboard1" lazy={lazyRoutes.LeaderBoardPage} />
        <Route path="/spaces/:id" lazy={lazyRoutes.SpacesPage} />
        {/* <Route path="/airdrop" lazy={lazyRoutes.Airdrop} /> */}
        <Route path="/studio/createitem" lazy={lazyRoutes.CreateItem} />
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Header burgerMenu />}>
          <Route path="/home" lazy={lazyRoutes.HomePage} />
          <Route path="/send" lazy={lazyRoutes.Send} />
          {/* <Route path="/profile" lazy={lazyRoutes.Profile} /> */}
          <Route path="/items" lazy={lazyRoutes.MyItems} />
          <Route path="/item/:id" lazy={lazyRoutes.ItemsPage} />
          <Route path="/activity" lazy={lazyRoutes.Activity} />
          <Route path="/studio" lazy={lazyRoutes.Studio} />
          <Route path="/studio/templates/:id" lazy={lazyRoutes.TemplatePage} />
          <Route path="/discover/:hash" lazy={lazyRoutes.DiscoverPage} />
          <Route path="/usersettings" lazy={lazyRoutes.UserSettings} />
          <Route path="/helpcenter" lazy={lazyRoutes.HelpCenter} />
          <Route path="/helpcenter/:tabId" lazy={lazyRoutes.HelpCenter} />
        </Route>
      </Route>
    )

    return createBrowserRouter(routesObj)
  })

  return <RouterProvider router={router} />
}

export default AppFrame
